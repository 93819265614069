import React,{useRef, useEffect} from 'react';


import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger); 


const VoterBox = () => {

    const hand = useRef()

    useEffect(()=> {
        let elHand = hand.current;


        var mainBox =document.querySelector('.question_banner')

        mainBox.addEventListener('mousemove', function(e) {

        

            if (e.pageX < window.innerWidth / 3 && e.pageX > window.innerWidth / 8 && e.pageY > window.innerHeight / 2 ) {
                parallaxIt(e, 20)
                
            }else if(e.pageX >( window.innerWidth / 3 + window.innerWidth / 3 ) && e.pageX< (window.innerWidth / 3 + window.innerWidth / 1.8)  && e.pageY > window.innerHeight / 2 ) {
                parallaxIt(e, -80)
            }
   
        })

        function parallaxIt(e, movement) {
            var relX = e.pageX / movement ;
            var relY = e.pageY / (movement * 1.5)
        
            

            gsap.to(elHand, {
                x: - relX ,
                y: relY,
              
            })
        }
        
    })

    return(
        <svg  viewBox="0 0 271 340" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g className="box">
                <path d="M220.4 339.9H36.4C29.7 339.9 24.1 334.7 23.6 328L12.2 185.8C11.6 178.3 17.5 171.9 25 171.9H231.8C239.3 171.9 245.2 178.3 244.6 185.8L233.2 328C232.7 334.7 227.1 339.9 220.4 339.9Z" fill="#00A6B5"/>
                <path d="M243.4 200.9L14.7 217L13.4 200.9H243.4Z" fill="#072B42"/>
                <path d="M11.1 200.9H245.7C251.8 200.9 256.8 195.9 256.8 189.8V153.6H0V189.8C0 195.9 4.99999 200.9 11.1 200.9Z" fill="#00A6B5"/>
                <path d="M6.09999 159.8H250.7C254.9 159.8 257.9 155.6 256.4 151.6L233.9 90C233 87.6 230.7 86 228.2 86H28.6C26 86 23.8 87.6 22.9 90L0.399996 151.6C-1.1 155.6 1.89999 159.8 6.09999 159.8Z" fill="#61C0CF"/>
                <path d="M129 216.6H123.7C119.5 216.6 116.1 220 116.1 224.2V243.3C116.1 247.5 119.5 250.9 123.7 250.9H129C133.2 250.9 136.6 247.5 136.6 243.3V224.2C136.6 220 133.2 216.6 129 216.6ZM133.1 241C133.1 244.2 130.9 246.7 128.2 246.7H124.8C122.1 246.7 119.9 244.1 119.9 241V226.5C119.9 223.3 122.1 220.8 124.8 220.8H128.2C130.9 220.8 133.1 223.4 133.1 226.5V241Z" fill="#072B42"/>
                <path d="M136.1 239.3H116.7C114.4 239.3 112.5 241.2 112.5 243.5V262.9C112.5 265.2 114.4 267.1 116.7 267.1H136.1C138.4 267.1 140.3 265.2 140.3 262.9V243.5C140.3 241.2 138.4 239.3 136.1 239.3Z" fill="#072B42"/>
                <path d="M135.3 235.1H116.9C114.3 235.1 112.2 233 112.2 230.4V202.8H140V230.4C140 233 137.9 235.1 135.3 235.1Z" fill="#072B42"/>
                <path d="M137.6 233.1H119.2C116.6 233.1 114.5 231 114.5 228.4V200.8H142.3V228.5C142.3 231.1 140.2 233.1 137.6 233.1Z" fill="#ABD8D5"/>
                <path d="M142.3 208V200.9H114.5V210L142.3 208Z" fill="#98BFBC"/>
                <path d="M129.2 216.1H123.9C119.7 216.1 116.3 219.5 116.3 223.7V232C117.1 232.7 118.2 233.1 119.3 233.1H119.8V226C119.8 222.8 122 220.3 124.7 220.3H128.1C130.8 220.3 133 222.9 133 226V233.1H136.6V223.7C136.8 219.5 133.4 216.1 129.2 216.1Z" fill="#98BFBC"/>
                <path d="M131.1 215.6H125.8C121.6 215.6 118.2 219 118.2 223.2V242.3C118.2 246.5 121.6 249.9 125.8 249.9H131.1C135.3 249.9 138.7 246.5 138.7 242.3V223.2C138.6 219 135.2 215.6 131.1 215.6ZM135.1 240C135.1 243.2 132.9 245.7 130.2 245.7H126.8C124.1 245.7 121.9 243.1 121.9 240V225.5C121.9 222.3 124.1 219.8 126.8 219.8H130.2C132.9 219.8 135.1 222.4 135.1 225.5V240Z" fill="#668687"/>
                <path d="M131.1 215.6H125.8C121.6 215.6 118.2 219 118.2 223.2V242.3C118.2 246.5 121.6 249.9 125.8 249.9H131.1C135.3 249.9 138.7 246.5 138.7 242.3V223.2C138.6 219 135.2 215.6 131.1 215.6ZM138 241.9C138 245.9 134.8 249.2 130.9 249.2H125.9C122 249.2 118.8 246 118.8 241.9V223.6C118.8 219.6 122 216.3 125.9 216.3H130.9C134.8 216.3 138 219.5 138 223.6V241.9Z" fill="#7EA7A9"/>
                <path d="M130.6 217.8H126.2C122.7 217.8 119.8 220.7 119.8 224.3V240.5C119.8 244.1 122.7 247 126.2 247H130.7C134.2 247 137.1 244.1 137.1 240.5V224.2C137 220.7 134.2 217.8 130.6 217.8ZM135.1 240C135.1 243.2 132.9 245.7 130.2 245.7H126.8C124.1 245.7 121.9 243.1 121.9 240V225.5C121.9 222.3 124.1 219.8 126.8 219.8H130.2C132.9 219.8 135.1 222.4 135.1 225.5V240Z" fill="#4A6666"/>
                <path d="M129.4 228.5H127.3C126.3 228.5 125.5 227.7 125.5 226.7V213.2C125.5 212.2 126.3 211.4 127.3 211.4H129.4C130.4 211.4 131.2 212.2 131.2 213.2V226.7C131.2 227.8 130.4 228.5 129.4 228.5Z" fill="#00A6B5"/>
                <path d="M138.1 238.3H118.7C116.4 238.3 114.5 240.2 114.5 242.5V261.9C114.5 264.2 116.4 266.1 118.7 266.1H138.1C140.4 266.1 142.3 264.2 142.3 261.9V242.5C142.3 240.1 140.4 238.3 138.1 238.3Z" fill="#FCC839"/>
                <path d="M138.1 238.3H135.5C137.8 238.3 139.7 240.2 139.7 242.5V261.9C139.7 264.2 137.8 266.1 135.5 266.1H138.1C140.4 266.1 142.3 264.2 142.3 261.9V242.5C142.3 240.1 140.4 238.3 138.1 238.3Z" fill="#FFE675"/>
                <path d="M135.1 238.3H133.7C135 238.3 136 240.2 136 242.5V261.9C136 264.2 135 266.1 133.7 266.1H135.1C136.4 266.1 137.4 264.2 137.4 261.9V242.5C137.4 240.1 136.3 238.3 135.1 238.3Z" fill="#FFE675"/>
                <path d="M120.1 261.8V242.5C120.1 240.2 122 238.3 124.3 238.3H118.8C116.5 238.3 114.6 240.2 114.6 242.5V261.9C114.6 264.2 116.5 266.1 118.8 266.1H124.3C121.9 266 120.1 264.2 120.1 261.8Z" fill="#F6A72D"/>
                <path d="M157.4 128.2H99.5C97 128.2 95 126.2 95 123.7V122.1C95 119.6 97 117.6 99.5 117.6H157.4C159.9 117.6 161.9 119.6 161.9 122.1V123.7C161.9 126.2 159.9 128.2 157.4 128.2Z" fill="#00A6B5"/>
                <path d="M138.1 238.3H118.7C116.4 238.3 114.5 240.2 114.5 242.5V261.9C114.5 264.2 116.4 266.1 118.7 266.1H138.1C140.4 266.1 142.3 264.2 142.3 261.9V242.5C142.3 240.1 140.4 238.3 138.1 238.3Z" fill="#FCC839"/>
                <path d="M138.1 238.3H135.5C137.8 238.3 139.7 240.2 139.7 242.5V261.9C139.7 264.2 137.8 266.1 135.5 266.1H138.1C140.4 266.1 142.3 264.2 142.3 261.9V242.5C142.3 240.1 140.4 238.3 138.1 238.3Z" fill="#FFE675"/>
                <path d="M135.1 238.3H133.7C135 238.3 136 240.2 136 242.5V261.9C136 264.2 135 266.1 133.7 266.1H135.1C136.4 266.1 137.4 264.2 137.4 261.9V242.5C137.4 240.1 136.3 238.3 135.1 238.3Z" fill="#FFE675"/>
                <path d="M120.1 261.8V242.5C120.1 240.2 122 238.3 124.3 238.3H118.8C116.5 238.3 114.6 240.2 114.6 242.5V261.9C114.6 264.2 116.5 266.1 118.8 266.1H124.3C121.9 266 120.1 264.2 120.1 261.8Z" fill="#F6A72D"/>
                <path d="M157.4 128.2H99.5C97 128.2 95 126.2 95 123.7V122.1C95 119.6 97 117.6 99.5 117.6H157.4C159.9 117.6 161.9 119.6 161.9 122.1V123.7C161.9 126.2 159.9 128.2 157.4 128.2Z" fill="#00A6B5"/>
                <path d="M128.5 260.5H128.3C127.3 260.5 126.5 259.7 126.5 258.7V250.9C126.5 249.9 127.3 249.1 128.3 249.1H128.5C129.5 249.1 130.3 249.9 130.3 250.9V258.7C130.3 259.7 129.5 260.5 128.5 260.5Z" fill="#00A6B5"/>
                <path d="M132 251.1C132 253.1 130.4 254.7 128.4 254.7C126.4 254.7 124.8 253.1 124.8 251.1C124.8 249.1 126.4 247.5 128.4 247.5C130.4 247.5 132 249.1 132 251.1Z" fill="#00A6B5"/>
                
            </g>

            <g className="hand" ref={hand}>
                <path d="M166.7 50.2C166.7 50.2 174 35.7 179.4 30.7C184.8 25.7 214.4 26.3 233.6 13.8C233.6 13.8 232.4 31.5 231.3 33C230.3 34.5 189.1 63.8 186.1 63.8C183.1 63.8 176.5 59.9 174.9 59.4C173.3 58.9 166.7 50.2 166.7 50.2Z" fill="#F19A85"/>
                <path d="M197.4 43.2C197.4 42.8 197 42.3 196.6 42.4C193.9 42.7 191.2 43.2 188.7 44.2C186.3 45.2 183.7 46.2 181.7 48C181.6 48.1 181.6 48.3 181.7 48.5C181.6 48.6 181.6 48.7 181.5 48.7L181.4 48.8C181.4 48.8 181.4 48.8 181.3 48.9C181.3 49 181.3 49 181.2 49.1C181.1 49.4 181.2 49.8 181.4 50C183.1 52.1 185 54 187 55.8C187.3 56 187.7 56.1 188 56C188.1 56.1 188.3 56.2 188.5 56C190 54.2 191.3 52.3 192.7 50.4C193.1 49.9 193.5 49.4 193.9 48.9C194.1 48.7 194.2 48.5 194.3 48.2C194.9 47.5 195.5 46.7 196.1 46C196.7 45.1 197.5 44.3 197.4 43.2Z" fill="#EE856A"/>
                <path d="M203.8 73.3L171.6 36.4L115 87.6L152.4 122.9L203.8 73.3Z" fill="white"/>
                <path d="M185.5 66.8C185.6 65.1 185.2 63.5 184.4 62C183.9 60.5 183 59.1 181.8 58C180.3 56.5 178.4 55.4 176.3 54.8C175.1 54.4 173.2 54.2 172.4 54.2C170.6 54.1 169 54.5 167.4 55.3C166.8 55.4 166.3 55.6 165.8 56C164.9 56.7 164 57.4 163.1 58.1C163 58.2 162.9 58.3 162.8 58.4C161.6 59.6 160.7 60.9 160.2 62.5C159.4 64 159 65.7 159.1 67.4C159.3 68.5 159.4 69.7 159.6 70.8C160 72.5 160.8 73.9 161.9 75.2C162.5 76.2 163.2 76.9 164.2 77.5C165.4 78.6 166.9 79.4 168.6 79.8C169.7 80 170.9 80.1 172 80.3C174.3 80.3 176.5 79.7 178.5 78.6C178.6 78.6 178.7 78.5 178.7 78.5C179.3 78.2 179.4 78 180.3 77.4C181.3 76.8 182 76.1 182.6 75.1C183.4 74.2 183.9 73.2 184.4 72.2C185.1 70.3 185.5 68.6 185.5 66.8Z" fill="#D0D0D0"/>
                <path d="M163 90.4C163.1 88.7 162.7 87.1 161.9 85.6C161.4 84.1 160.5 82.7 159.3 81.6C157.8 80.1 155.9 79 153.8 78.4C152.6 78 150.7 77.8 149.9 77.8C148.1 77.7 146.5 78.1 144.9 78.9C144.3 79 143.8 79.2 143.3 79.6C142.4 80.3 141.5 81 140.6 81.7C140.5 81.8 140.4 81.9 140.3 82C139.1 83.2 138.2 84.5 137.7 86.1C136.9 87.6 136.5 89.3 136.6 91C136.8 92.1 136.9 93.3 137.1 94.4C137.5 96.1 138.3 97.5 139.4 98.8C140 99.8 140.7 100.5 141.7 101.1C142.9 102.2 144.4 103 146.1 103.4C147.2 103.6 148.4 103.7 149.5 103.9C151.8 103.9 154 103.3 156 102.2C156.1 102.2 156.2 102.1 156.2 102.1C156.8 101.8 156.9 101.6 157.8 101C158.8 100.4 159.5 99.7 160.1 98.7C160.9 97.8 161.4 96.8 161.9 95.8C162.6 93.9 163 92.2 163 90.4Z" fill="#D0D0D0"/>
                <path d="M179.3 69.7C179.2 69.3 179.1 68.8 179.1 68.4C179 67.9 179 67.8 179.1 68.1C179.1 67.8 179 67.5 179 67.1C179 66.8 178.9 66.5 178.9 66.1C178.9 66 178.9 65.9 178.9 65.8C178.6 63.8 178.5 61.8 178.5 59.9C178.5 57.9 178.5 55.9 178.5 53.9C178.5 53.3 178.4 52.7 178.3 52.1C178.2 51.8 178.2 50.9 178.1 50.5C177.8 49.4 177.5 47.8 176.2 47.5C175 47.2 174.4 48.3 173.7 49C173.1 49.6 172.7 50.4 172.4 51.2C171.5 52.6 171.2 54.2 170.9 55.9C170.6 57.9 170.5 60 170.6 62C170.7 64 170.9 65.9 171.2 67.9C171.2 68 171.2 68.1 171.3 68.2C171.2 68.2 171.1 68.2 171 68.2C169.3 68.1 167.5 67.8 165.9 68.5C164.7 69 164 70.6 165.1 71.5C166.5 72.7 168.3 73.2 170 73.6C171.7 74.1 173.5 74.5 175.2 75C177.5 75.6 179.1 73.9 179.4 71.8C179.6 72.2 179.6 71.9 179.6 70.9C179.5 70.6 179.5 70.1 179.3 69.7Z" fill="#E43032"/>
                <path d="M265.6 27.4C265.6 27.4 242 54.4 233.9 59.6C225.9 64.8 210.8 64.3 206.4 66.9C202 69.5 185.1 84.8 180.2 85.6C175.3 86.4 170.8 80.8 174.4 74.9C178 68.9 188.8 56.6 193.2 49C197.6 41.5 245.7 4.6 245.7 4.6C245.7 4.6 262.2 17.5 265.6 27.4Z" fill="#F19A85"/>
                <path d="M245.7 0L234.1 9.3C234.1 9.3 252.1 35.7 258.5 39.2C258.5 39.2 266.9 33.4 270.1 28.2C270.1 28.1 250.6 4.6 245.7 0Z" fill="#083753"/>
                <path d="M185.1 75.2C184.7 74.6 184.1 74.1 183.6 73.6C183 73 182.3 72.2 181.5 72C180.4 71.7 179.3 72.4 178.5 73.1C177.5 74.1 176.7 75.5 176.3 76.8C176 77.7 176 78.7 176.3 79.6C176.6 80.5 177.2 81.4 178.2 81.8C178.4 81.9 178.6 82 178.8 82C180.1 82.4 181.5 81.9 182.6 81.2C183.4 80.7 184.4 80 184.9 79.1C185.2 78.8 185.4 78.4 185.6 77.9C185.8 77 185.6 76 185.1 75.2Z" fill="#F5B6A8"/>
                <path d="M195.3 68.2C195.1 67.6 194.6 67.1 194.1 66.7C193.7 66.3 193.3 65.9 192.9 65.6C192.5 65.2 192 64.9 191.5 64.6C191.3 64.5 191 64.3 190.8 64.2C190.7 64.1 190.5 64.1 190.4 64C190.3 64 190.1 63.9 190 63.9C189.6 63.8 189.3 64.3 189.6 64.6L189.7 64.7C189.9 65.1 190.4 65.4 190.7 65.7C191.1 66.1 191.5 66.4 191.9 66.8C192.3 67.2 192.7 67.5 193 67.9C193.4 68.3 193.7 68.8 194.2 69.2C194.5 69.4 194.9 69.3 195.1 69.1C195.2 68.8 195.4 68.5 195.3 68.2Z" fill="#EE856A"/>
                <path d="M195 65C195 64.9 195.1 64.8 195.1 64.7C195.1 64.6 195.1 64.5 195 64.4C195 64.4 195 64.4 195 64.5C195 64.4 195 64.3 194.9 64.2V64.1C194.9 64 194.8 64 194.8 63.9C194.7 63.7 194.5 63.6 194.3 63.4C194.2 63.3 194 63.2 193.8 63.2C193.7 63.2 193.7 63.2 193.6 63.2C193.5 63.2 193.4 63.2 193.4 63.2C193.3 63.2 193.3 63.2 193.2 63.3C192.9 63.4 192.7 63.8 193 64.1C193.1 64.2 193.2 64.3 193.3 64.4L193.2 64.3C193.3 64.3 193.3 64.4 193.4 64.4C193.6 64.6 193.8 64.8 194 65L193.9 64.9C194 65 194 65 194.1 65.1L194.2 65.2C194.2 65.2 194.3 65.2 194.3 65.3H194.4C194.5 65.3 194.6 65.3 194.7 65.3H194.5C194.6 65.4 194.8 65.3 194.9 65.2C194.9 65.2 194.9 65.2 194.9 65.1C195 65.1 195 65 195 65Z" fill="#EE856A"/>
                <path d="M261.9 27.7C261.7 27.5 261.6 27.3 261.3 27.1C261.2 26.9 261.1 26.7 260.9 26.5C259.8 25.4 258.3 26.1 257.3 26.9C256.3 27.7 255.6 28.9 255.9 30.3C256.1 31.6 257.3 32.5 258.6 32.6C259.8 32.7 261 32.2 261.7 31.2C262.5 30.1 262.6 28.7 261.9 27.7Z" fill="#074C7D"/>
                
            </g>
            

            <g className="cover">
                <path d="M18 129H236V160H18V129Z" fill="#61C0CF"/>
                <path d="M19 160H237V200H19V160Z" fill="#00A6B5"/>
            </g>
        </svg>


    )
}

export default VoterBox 