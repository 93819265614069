import React, {useEffect} from "react";
import {  NavLink } from 'react-router-dom';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Row, Col } from "antd";

import { ArrowRightOutlined } from "@ant-design/icons";

import PillarData from '../Pillars/PillarsData'
import EventData from "../Events/EventData";
import InstagramFeed from "react-ig-feed";
import "react-ig-feed/dist/index.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";

import ScrollTop from '../../components/ScrollTop'

import "./home.scss";
import { LogoIcon } from "../../components/Logo";
import ProgramList from "../Programs/ProgramList";

gsap.registerPlugin(ScrollTrigger); 

const Home = () => {
    useEffect(() => {
      gsap.from('.intro_text .title h3', { opacity: 0, scaleX: 1.1,
        scrollTrigger: { trigger: '.intro_text .title', start: 'top center+=20%',}
      })

      gsap.from('.intro_text .bg svg',{ rotation: 180 * 2 ,
        scrollTrigger: { trigger: '.intro_text .title', start: 'top center+=20%', scrub: 2}
      })

      gsap.fromTo('.pcard', {
        scale: 1.05,
        opacity: 0,    
      },{
        scale: 1,
        opacity: 1,
        duration: 0.4,
        stagger: 0.2,
        ease: 'none',
        scrollTrigger: {
          trigger: '.pillars',
          start: 'top center+=20%',
          toggleActions: 'play reset restart none',
        }
      })
      
    },[])

    return (
        <>
          <ScrollTop />
          
          <section className="home_banner">
              <div className="container_large">
                  <div className="text">
                      <h2>Himalayan Institute of Civic Action and Policy</h2>

                      <p>Dedicated to advancing the essence of democracy through Education, Awareness & Advocacy</p>

                      <a href="">
                          Learn More <ArrowRightOutlined />
                      </a>
                  </div>
              </div>
          </section>

          <section className="intro_text">
              <div className="container_large">
                  <div className="wrap">
                      <div className="title">
                          <h3>Dedicated to advancing the essence of democracy through <b>Education, Awareness & Advocacy</b></h3>
                      </div>

                      <div className="desc">
                          <p>
                              A not-for-profit, non-partisan organization dedicated to advancing the essence of democracy through education, awareness, and advocacy at multiple levels. iCAP is an initiative to bridge the gap between
                              citizens and government in a federal Nepal that demands active people’s participation and systemic efficiency for its success.
                          </p>

                          <p>
                              In alignment with strengthening federalism in Nepal, we work to enable active citizens' engagement and enhance the capacity of stakeholders in the political sphere. iCAP believes in the power of people and
                              policy and hence moves its way towards reformation through a bottom-up approach.
                          </p>
                      </div>
                  </div>
              </div>

              <div className="bg">
                <LogoIcon />

                
              </div>
          </section>

          <section className="pillars">
            <div className="container_large">
              <Row gutter={[10, 15]} type="flex">
                {
                  PillarData.map((val,index)=> {
                    return(
                      <Col lg={6} sm={24} key={index}>
                        <div className="pcard" key={index} style={{ backgroundColor: `${val.background}` }}>
                          <div className="logo">
                            <LogoIcon />
                          </div>

                          <div className="main-text">
                            <h4>{val.title}</h4>
                            <p>{val.subtitle}</p>
                          </div>

                          <div className="foot">
                            <span>0{index+1}</span>
                            <article dangerouslySetInnerHTML={{ __html: val.text }} ></article>
                          </div>
                        </div>

                      </Col>
                    )
                  })
                }
              </Row>
            </div>
          </section>

          <ProgramList />

          <section className="events">
            <div className="container">
              <div className="head">
                <h3>Events</h3>
                <p>We aim to improve critical elements of election administration, including voter registration, voter list preparation, voters' education and training.</p>
              </div>
            </div>

            <Swiper
              className="home_news_slides"
                breakpoints={{
                640: {
                    slidesPerView: 1,
                 
                },
                768: {
                    slidesPerView: 2,
                
                },
                1024: {
                    slidesPerView: 2,
               
                },
                }}
            >
              {
                EventData.map((val,index) => {
                  return(
                    <SwiperSlide key={index}>
                      <div className="ev_card" style={{background: `url(${val.image})` }}  >
                        <div className="wrap">
                          <h3>{val.title}</h3>
                          <article dangerouslySetInnerHTML={{ __html: val.text }} ></article>

                          <NavLink  
                              to="/event-detail"
                              state={{ detail: val }}
                          >
                              Learn More <ArrowRightOutlined />
                          </NavLink>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>

    
          </section>

          {/* <div className="insta_feed">
            <div className="head">
              <h3>Follow us on Instagram <b>iCAP Nepal</b> </h3>
            </div>

            <InstagramFeed
              token="IGQVJXNGcwLXlpT0lwN0JTbmtuVHBHbVVxNUlzeU5US3ZACN0lkNzNzWGJBM2RRcjRJS0xtMVdxS0tLUXNnY29Na1FwN1VmMHpIMjZAZAdHBUalpmTURndHFuakFrT2loMDFBREtLYXZAkeWh5Tm1OTXpZAMAZDZD"
              counter="12"
            />
          </div> */}
        </>
    );
};

export default Home;
