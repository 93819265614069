import React from "react";

import { Row, Col } from "antd";

import { LogoMain } from "../../components/Logo";

import "./footer.scss";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container_large">
          <Row gutter={[30, 15]}>
            <Col lg={6} sm={24}>
              <div className="footer_card">
                <div className="logo">
                  <LogoMain />
                </div>

                <div className="socail">
                  <a href="https://www.facebook.com/ICAPNepal/" target="_blank">
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                  <a
                    href="https://www.instagram.com/icap.nepal/"
                    target="_blank"
                  >
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/icapnepal/"
                    target="_blank"
                  >
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                </div>
              </div>
            </Col>

            <Col lg={5} sm={24}>
              <div className="footer_nav">
                <h4>Sitemap</h4>
                <ul>
                  <li>
                    <a href="">About</a>
                  </li>
                  <li>
                    <a href="">Our Four Pillars</a>
                  </li>
                  <li>
                    <a href="">Events</a>
                  </li>
                  <li>
                    <a href="">News & Publication</a>
                  </li>
                  <li>
                    <a href="">Downloads</a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={6} sm={24}>
              <div className="footer_nav">
                <h4>Our Four Pillars</h4>
                <ul>
                  <li>
                    <a href="">Electoral Support</a>
                  </li>
                  <li>
                    <a href="">Parliamentarian Support</a>
                  </li>
                  <li>
                    <a href="">Political Party Support</a>
                  </li>
                  <li>
                    <a href="">Citizen Support</a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={7} sm={24}>
              <div className="footer_nav">
                <h4>Contact </h4>
                <ul>
                  <li>
                    <a href="#">
                      House No. 260, Lamingtan / Sheetal Marg, Baluwatar
                    </a>
                  </li>
                  <li>
                    <a href="#">P.O. Box: 11772, Kathmandu, Nepal</a>
                  </li>
                  <li>
                    <a href="#">+977-1-4420784 / 01-4440085</a>
                  </li>
                  <li>
                    <a href="#">info@icapnepal.org</a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <div className="copyright">
            <p>© 2022 iCAP Nepal. All Rights Reserved. </p>
            <p>
              Powered by{" "}
              <a href="" target="_blank">
                Hue Shine
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
