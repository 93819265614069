import React from 'react'
import { useLocation } from 'react-router-dom'
import ScrollTop from '../../components/ScrollTop'
import './about.scss'

const Team = () => {
    const location = useLocation()
    const  {detail}  = location.state
  return (
    <>
        <ScrollTop />

        <section className="team_detail">
            <div className="container_large">
                <div className="wrap">
                    <div className="img">
                        <img src={detail.image} alt="" />
                    </div>

                    <div className="text">
                        <h3>{detail.name}</h3>
                        <h6>{detail.post}</h6>

                        <article dangerouslySetInnerHTML={{ __html: detail.description }} ></article>
                    </div>
                </div>
            </div>
        </section>


    
    </>
  )
}

export default Team