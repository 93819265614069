const TeamData = [
    {
        category: 'BOD',
        image: './images/team/Aanand Mishra.jpg',
        name: 'Aanand Mishra',
        post: 'Executive Director',
        description: `Aanand Mishra is a development professional, activist, and social entrepreneur who embarked on the journey of the social sector at the age of 15. Throughout his 18 years career, he has worked on various development issues for organizations like UN Mission in Nepal, UNICEF, and the Government of Nepal. He also serves as a Founder and President of CREASION, Nepal.`
    },
    {
        category: 'BOD',
        image: './images/team/Dr. Preeti Kumari Mandal.jpg',
        name: 'Dr. Preeti Kumari Mandal',
        post: 'Joint Secretary',
        description: `
                    <p>
                    Dr. Priti Mandal is an assistant professor at Tribhuwan University, Nepal. She has completed her graduation and post-graduation in the field of education from Tribhuwan University, Nepal, and Ph.D. from the Faculty of Education, Banaras Hindu University, India. Women empowerment and education was the main area of study during her Ph.D. In addition, she has conducted several types of research and has been working in the area of women’s human rights and their empowerment for the last 10 years.
                    </p>

                    <p>
                    She has also been working as a Gender Equality and Social Inclusion consultant for several years. Dr. Mandal is a member of the editorial board of several journals published in Nepal. She was the former bureau member of South Asians for Human Rights, representing Nepal. Currently, she is the general secretary of NNAGT (National Network Against Girl’s Trafficking) and an active member of IGFF (Inter-generational Feminist Forum).
                    </p>
        `    
    },
    {
        category: 'BOD',
        image: './images/team/Bhushita Vasistha.jpg',
        name: 'Bhusita Vasistha ',
        post: 'Vice President',
        description: ``
    },
    {
        category: 'BOD',
        name: 'Ekal Silwal ',
        image: './images/team/Ekal Silwal.JPG',
        post: 'Secretary',
        description: `
                <p>
                He has experience of active practice in mainstream journalism for more than two decades. He raised several crucial issues of violent conflict and its harmful impact on civilians during the decade-long insurgency in Nepal. In a particular issue regarding forcefully engaging minor combatants in the revolt, his work in national media drew serious attention from the United Nations Security Council and concerned parties in Nepal
                </p>

                <p>
                He has passion to work for the empowerment of people struggling in grassroots.
                </p>
        `
    },
    {
        category: 'BOD',
        name: 'Pradip Pariyar',
        image: './images/team/Pradeep Pariyar.jpg',
        post: 'Treasurer',
        description: `
                <p>
                Pradip Pariyar is currently serving as the Executive Chairperson of Samata Foundation, bringing nearly two decades of expertise in formulating national and international policies for youth, peace, and social justice. Samata Foundation is a leading independent think-tank with specialization in the area of caste-based discrimination and marginalization. Since its establishment, Samata Foundation is engaged in knowledge production to provide concrete and scientific information to conduct evidence-based informed policy advocacy, to help formulate new policies, and amend the existing policies concerning the welfare of Dalit and the most marginalized communities.
                </p>

                <p>
                For his contribution and commitment towards empowering youth and promoting social justice, the World Economic Forum this year has recognized Mr. Pariyar in its 2020 class of Young Global Leaders. 
                </p>
        `
    },
    {
        category: 'Team',
        image: './images/team/Neha Shrestha.JPG',
        name: 'Neha Shrestha  ',
        post: 'Director, Operations and Programs',
        description: `
                <p>
                She is a coordinator for the Myanmar-based International Peace Support Group (IPSG). She also served National Democratic Institute, Nepal as a Senior Program Officer for the Political Parties Program in Nepal. Since 2011, she has worked closely with parliament, the election commission, and political parties in Nepal. She also observed the second constituency assembly election of 2013 and local, provincial, and federal elections of 2017/18.
                </p>

                <p>
                She brings a wealth of experience and understanding of Nepal’s politics and the nature of civil society organizations. She holds her master degree in sociology from Tribhuvan University, Nepal
                </p>
        `
    },
    {
        category: 'Team',
        image: './images/team/MJ Jha.jpeg',
        name: 'MJ Jha',
        post: 'Director, Electoral Support Program',
        description: `
                <p>
                MJ Jha is a writer, socio-political researcher, and political consultant. He has worked with political parties/ candidates in several countries of the Asia Pacific region through the Asia Pacific Greens Federation. He has worked as a political consultant for more than 30 Nepali politicians. His effort was valuable for the candidates/political parties through strategic communication, community outreach, media relations, and fundraising campaigns.
                </p>

                <p>
                He was awarded the “Youth Talent Award 2016” by the Government of Nepal and “Youth Inspiration Award by Global Lead Network. 
                </p>
        `
    },
    {
        category: 'Team',
        image: './images/team/Prerak Joshi.png',
        name: 'Prerak Joshi',
        post: 'Electoral Support Program',
        description: `
                <p>
                He is a BA graduate majoring in Global Studies and Social Entrepreneurship from Thammasat University, Thailand. He is passionate about addressing key societal issues and has worked as a researcher in a video documentary highlighting the role of three ‘ WEDU Rising Stars’ shedding light on the issue of women entrepreneurship.
                </p>

                <p>
                Currently, working as a program development intern at iCAP, Prerak has delved into the political realm of Nepal and set his objective to design awareness and training campaigns.
                </p>
        `
    },

]

export default TeamData