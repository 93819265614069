
import Preload from './components/Preload/Preload';

import './main.scss'

function App() {
  
  return (
    <>

 
      <Preload />
    </>
  );
}

export default App;
