import React, {useState} from 'react'
import { BrowserRouter as Router } from 'react-router-dom';

import { Modal, Button } from 'antd'

import { LogoMain } from '../Logo'
import VoterBox from './VoterBox'


import './style.scss'
import Layout from '../../Layout'


const Preload = () => {
    const [enterSite, setEnterSite] = useState(false)
    const [register, setRegister] = useState(false)


    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModalYes = () => {
      setIsModalVisible(true);
  
      setRegister(true)
      setEnterSite(true)
    };

    const showModalNo = () => {
        setIsModalVisible(true);
        
        setEnterSite(true)
        setRegister(false)
        
    };

    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
      setRegister(true)
   
    };
  

      


    return (
        <>
            {
                enterSite ? <>
                {
                    register == false ? <Modal title="Local Election in Nepal 2022" footer={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
           

                    <video className='videoTag' autoPlay loop controls >
                        <source src="./images/LOCAL ELECTION IN NEPAL, 2022 (2).mp4" type='video/mp4' />
                    </video>

                </Modal> : null
                }
                
                    <Router> <Layout /></Router>
                </> : <section className="question_banner">
                                            <div className="wrap" id='bannerqsn'>
                                                <div className="logo">
                                                    <LogoMain />
                                                </div>

                                                <div className="qsn">
                                                    <h2><span>DO YOU KNOW </span>HOW TO VOTE?</h2>
                                                </div>
                                            </div>

                                            <div className="vote">
                                                <VoterBox />
                                            </div>

                                            <div className="voteBtn">
                                                <button id='yes' onClick={showModalYes} >Yes, I Do </button>
                                                <button id='no' onClick={showModalNo} >No, I Do Not</button>
                                            </div>
                                        </section>
            }

            
            

           
        </>
    )
}

export default Preload