import React from 'react'
import Banner from '../../components/Banner/Banner'
import TeamData from './TeanData';
import {  NavLink } from 'react-router-dom';
import { Row, Col } from 'antd';
import ScrollTop from '../../components/ScrollTop'

import { ArrowRightOutlined } from '@ant-design/icons';

import 'antd/dist/antd.css'; 
import './about.scss'

const aboutData = [
  {
    title: 'Our Mission',
    text: `Work in collaboration with multiple stakeholders- from grassroots to policy level- by educating and empowering the citizens and strengthening the capacities of people's representatives`,
    image: './images/our_mission.JPG'
  },
  {
    title: 'Our Vision',
    text: `Institutionalize a strong federal system based on the inclusive and active participation of aware citizens and capable leaders`,
    image: './images/our_vision.JPG'
  },
  {
    title: 'Our Goals',
    text: `Increase citizens’ meaningful representation in public spheres and enhance the capacity of representatives through research, awareness, advocacy and dialogues`,
    image: './images/about.jpg'
  },

]

const objective = [
  {
    title: `To play the role of a bridge between the people and political representatives `
  },
  {
    title: `To work on increasing public participation, fair election process and good governance`
  },
  {
    title: `To provide consultation and capacity-building training to all three tiers of government `
  },
  {
    title: `Learn more about policing accountability and justice. Use data as a tool for change.`
  },
]



const About = () => {
  return (
    <>
      <ScrollTop />

      <Banner 
        image='url(./images/about.jpg)'
        text= '<h2>Dedicated to advancing the essence of democracy through <b>Education, Awareness & Advocacy</b></h2>'
      />

      <div className="about_text">
        <div className="container">
          <h3><b>An initiative to bridge the gap</b> between citizens and government in a federal Nepal that demands active people’s participation and systemic efficiency for its success.</h3>

          <p>
             A not-for-profit, non-partisan organization dedicated to advancing the essence of democracy through education, awareness, and advocacy at multiple levels
             In alignment with strengthening federalism in Nepal, we work to enable active citizens' engagement and enhance the capacity of stakeholders in the political sphere. iCAP believes in the power of people and policy and hence moves its way towards reformation through a bottom-up approach. 
          </p>

          <p>
            iCAP conducts a multitude of situation-specific programs designed through intensive research and expertise to meet its broader discourse to fulfill the values of democracy.    
          </p>
        </div>
      </div>

      <section className="mvg">
        <div className="container_large">
          <Row>
            {
              aboutData.map((val,index) => {
                return(
                  <Col lg={12} sm={24} key={index}>
                    <div className="card">
                      <figure>
                        <img src={val.image} alt="" />

                        <svg width="98" height="273" viewBox="0 0 98 273" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.426528 0.828129L14.6836 23.409C28.808 45.9905 57.5211 91.1514 61.1331 136.421C64.8777 181.691 43.8528 227.068 33.3403 249.756L22.8278 272.445L97.1033 272.122L97.005 249.48C96.9066 226.837 96.71 181.553 96.5134 136.268C96.3168 90.983 96.1201 45.6982 96.0218 23.0559L95.9235 0.413483L0.426528 0.828129Z" fill="#0099FF" fillOpacity="0.79"/>
                        </svg>


                      </figure>

                      <div className="desc">
                        <h4>{val.title}</h4>
                        <p>{val.text}</p>
                      </div>
                    </div>
                  </Col>
                )
              })
            }

          </Row>
        </div>
      </section>

      <section className="objectives" style={{background: 'url(./images/objective.jpg)' }}>
        <div className="container">
          <div className="head">
              <h3> iCAP conducts a multitude of situation-specific programs.
                <b> Here's our objectives</b>
              </h3>
          </div>
        </div>
        
        <div className="container_large">
          <Row>
            {
              objective.map((val, index) => {
                return(
                  <Col  lg={6} sm={12}  key={index}>
                    <div className="obj_card" key={index}>
                        <h6>0{index + 1}</h6>
                        <p>{val.title}</p>
                    </div>
                  </Col>
                )
              })
            }

            
          </Row>
        </div>
      </section>

      <section className="team">
        <div className="container_large">
            <div className="team_wrap">
              <div className="team_head">
                <h3>Board of Directors</h3>
              </div>

              <Row gutter={[30, 30]}>
                {
                  TeamData.filter(TeamData => TeamData.category === 'BOD').map((BodData, index) => (
                
                    <Col lg={8} sm={24} key={index}>
                      <div className="team_card">
                        <img src={BodData.image} alt="" />
                        <h4>{BodData.name}</h4>
                        <p>{BodData.post}</p>

                        <NavLink  
                            to="/team"
                            state={{ detail: BodData }}
                        >
                            GET TO KNOW <ArrowRightOutlined />
                        </NavLink>
                        
                      </div>
                    </Col>
                  ))
                }
              </Row>

            </div>

            <div className="team_wrap">
              <div className="team_head">
                <h3>Our Team</h3>
              </div>

              <Row gutter={[30, 30]}>
                {
                  TeamData.filter(TeamData => TeamData.category === 'Team').map((BodData, index) => (
                
                    <Col lg={8} sm={24} key={index}>
                      <div className="team_card">
                        <img src={BodData.image} alt="" />
                        <h4>{BodData.name}</h4>
                        <p>{BodData.post}</p>

                        <NavLink  
                            to="/team"
                            state={{ detail: BodData }}
                        >
                            GET TO KNOW <ArrowRightOutlined />
                        </NavLink>
                      </div>
                    </Col>
                  ))
                }
              </Row>

            </div>
        </div>
      </section>
    </>

  )
}

export default About