const EventData = [
    {
        title: 'Round Table Discussion on Preparation for Local Election 2022 ',
        image: './images/roundtable.jpg',
        text: `
            <p>
                iCAP organized a round-table discussion with the three major stakeholders of democracy: politicians, the media, and CSOs. The purpose of the round-table discussion was to provide a platform for discourse among powerful stakeholders to address lessons learned in the past and prepare for the upcoming election in 2022. It aimed to increase citizenry participation and involvement in the voting processes through voter education and registration programs while enhancing the democratic system.
            </p>

            <p>
                The round-table discussion was completely based on the local elections and the preparations performed by representatives of major political parties, CSOs, and media outlets.
            </p>
        `
    },
    {
        title: 'Research Trip to Province 2',
        image: './images/our_vision.JPG',
        text: `
            <p>
            The team explored all eight districts, and conducted primary research at the grassroots level about the political awareness, local governance system, and the socio-cultural and political environment of the district. Extensive research were carried out in the Tilathi Koiladi Rural Municipality, Rajbiraj; Baluwa Municipality and ManraSisawa Municipality , Mahottari; Birgunj Metropolitan, Parsa; Kalaiya Municipality, Bara; Janakpur Sub- Metropolitan, Dhanusha, Kalyanpur Municipality, Siraha; Malangwa Municipality, Sarlahi. 
            </p>
        `
    },

    {
        title: 'Ekjut:United We Vote',
        image: './images/our_mission.JPG',
        text: `
            <p>
            iCAP in collaboration with Center for Entrepreneurship and Innovation (CEI) launched the ‘Ekjut:United We Vote’ pledge campaign to initiate a non-partisan coalition of diverse organizations with the goal to promote free and fair elections and increase voters participation in the upcoming elections. The members of the alliance have made a commitment to ensure the rights of employees to vote through flexible work schedules for voting commitments, as well as freedom of choice and expression.  40 organizations have signed the pledge till date. 
            </p>
        `
    },

    {
        title: 'Mock Election',
        image: './images/event2.jpg',
        text: `
            <p>
            iCAP organized a mock election in Chitwan with 59 participants with the overall voters turnout ratio at 88.8%. The total number of invalid votes recorded was 25.64%. First time voters, especially college students participated in the mock elections and were briefed thoroughly about the governance system and electoral processes of Nepal. iCAP aims to conduct further voter education related programs through the alliance network at the provincial level. 

            </p>
        `
    },
]

export default EventData