import React from 'react'
import { useLocation } from 'react-router-dom'
import Banner from '../../components/Banner/Banner'
import ScrollTop from '../../components/ScrollTop'
import './event.scss'

const EventDetail = () => {
    const location = useLocation()
    const  {detail}  = location.state
  return (
    <>
        <ScrollTop />

        <Banner
            image={`url(${detail.image})`}
            text={`<h2>${detail.title} </h2>`}

        />

        <div className="event_text">
            <div className="container">
            <article dangerouslySetInnerHTML={{ __html: detail.text }} ></article>
            </div>
        </div>
    
    </>
  )
}

export default EventDetail