import React from 'react'

import './banner.scss'

const Banner = (props) => {
  return (
    <>
        <section className='inner_banner' style={{background: `${props.image}` }}>
            <div className="container_large">
                <div className="text">

                  <article dangerouslySetInnerHTML={{ __html: props.text }} ></article>
                   
                </div>
            </div>
        </section>
    </>
  )
}

export default Banner