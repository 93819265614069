import React,{useEffect, useRef } from 'react'
import {  NavLink } from 'react-router-dom';
import ProgramData from './ProgramData'
import { Row, Col } from "antd";

import { ArrowRightOutlined } from "@ant-design/icons";

import './programs.scss'

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger); 


const ProgramList = () => {
  const programRefs = useRef([])
    programRefs.current = [];

    const addToRefs = el => {
        if(el && !programRefs.current.includes(el)){
            programRefs.current.push(el)
        }
    };

  useEffect(() => {
    programRefs.current.forEach((el, index) => {
      const figure = el.querySelector('.programs figure img')
      const title = el.querySelector('.programs .text h4')
      const text = el.querySelector('.programs .text p')
      const link = el.querySelector('.programs .text a')
      ScrollTrigger.refresh();


      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: 'top center+=15%',
        },
      })

      tl.from(figure, {scale: 0.8,opacity: 0,})
        .from(title, {scaleX: 1.15,opacity: 0, }, 0)
        .from(text, {y: 20,opacity: 0,})
        .from(link, {y: 20,opacity: 0,})
    })
  })

  return (
    <>
        <section className="programs">
            <div className="container">
              <div className="head">
                <h3>iCAP Programs</h3>
                <p>We aim to improve critical elements of election administration, including voter registration, voter list preparation, voters' education and training.</p>
              </div>

              <div className="wrap">
                {
                  ProgramData.map((val,index) => {
                    return(
                      <div className="pro_card" ref={addToRefs} key={index}>
                        <Row gutter={[100, 15]} type="flex">
                          <Col lg={12} sm={24} >
                            <figure>
                              <img src={val.image} alt="" />
                            </figure>
                          </Col>

                          <Col lg={12} sm={24} >
                            <div className="text">
                              <h4>{val.title}</h4>
                              <p>{val.text}</p>
                              <NavLink  
                                to="/program-detail"
                                state={{ detail: val }}
                              >
                                Learn More <ArrowRightOutlined />
                              </NavLink>
                         
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )
                  })
                }

              
              </div>
            </div>
        </section>
    </>
  )
}

export default ProgramList