const ProgramData = [
    {
        title: 'Electoral Support Program',
        image: './images/our_vision.JPG',
        text: `The Electoral Support Program helps build the capacity of our electoral institutions—namely the Election Commission Nepal (ECN) and the District and State offices of the same to hold transparent elections.  We aim to improve critical elements of election administration, including voter registration, voter list preparation, voters' education and training.  Strengthening our electoral institutions is crucial to advancing the country’s long-term political stability and lays the foundation for peaceful transfers of power.`,
        description: `<ul>
            <li><strong>ROUND TABLE DISCUSSION</strong> Preliminary initiative to understand the role of three major stakeholders of democracy, iCAP hosted a roundtable discussion on the topic of ‘Preparation for the Upcoming Local Election,2022’.  Members of six major political parties, media houses and CSO group attended the program and shared their learning experiences from the past and their role in the upcoming elections. </li>
            <li><strong>DIGITAL MEDIA FOR CIVIC AWARENESS AND EDUCATION</strong> Interactive and informative social media posts highlighting the importance of voters registration and education, electoral system, statistics  </li>
            <li><strong>WORKSHOPS AND KNOWLEDGE EXCHANGE INITIATIVES</strong>  An interactive workshop session with youth in Chitwan, Kathmandu and Mahottari on the topic of ‘Formation of Government and Electoral System in Nepal.   </li>
            <li><strong>MOCK ELECTION</strong> iCAP hosted a mock election in Chitwan (59 participants) and in Kathmandu (20 participants)  </li>
            </ul>   `
    },
    {
        title: 'Parliamentarian support program  ',
        image: './images/our_mission.JPG',
        text: `As representatives of the people, parliamentarians need to be well-versed with both hard and soft skills. With visible differences in opportunities and exposure across various regions of Nepal, the capacities and knowledge of the pool of parliamentarians also differ. A common program that binds them with the required knowledge, skills, and expertise is a necessity to help them deliver on their action plans.`,
        description: `
                    <ul>
                        <li>Capacity enhancement training for representatives of all three tiers of government </li>
                        <li>Support to the Parliamentary Secretariat</li>
                        <li>Support to the Parliamentary Committees </li>
                    </ul>
        `
    },
    {
        title: 'Political Party Support Program ',
        image: './images/objective.jpg',
        text: `The major political parties in the country are liable to its citizens and are expected to bring about stability in the country along with concrete outcomes. In March 2017, the LP (Legislative Parliament) passed the Political Party Bill, which includes criteria for inclusivity, internal democracy, and party conventions. As a result of the bill’s passage and the adoption of the new constitution, political parties could establish policies that benefit all citizens, including women and other marginalized groups. A greater emphasis on communication and dialogue is essential to consolidate democratic successes while outlining the way further future reforms will be the primary focus of iCAP work.`,
        description: `
                    <ul>
                        <li>Inclusive Political Participation through Interparty Women Network (IPWN) </li>
                        <li>Youth Engagement through Inter Party Youth Network (IPYN) </li>
                    </ul>            
        `
    },
    {
        title: 'Citizen Support Program ',
        image: './images/about.jpg',
        text: `In accordance with the Youth Vision (2025), iCAP functions to empower and educate the citizens and the youth in the priority group. Educational workshops, awareness programs, leadership training and expert intervention to design a holistic project is the major objective under the Citizen Support Program. With the onset of elections in 2022, voter registration and education plays a crucial role in strengthening the democratic system. Involving the youths requires them to understand the structure and formation of the government, with knowledge of policies and laws under the constitution. Hence, education plays a vital role in prolonging the citizen support program`,
        description: `
                    <ul>
                        <li>Collaborate with existing  social organizations to initiate an alliance group across seven different provinces. </li>
                        <li>Conduct activities relating to Voter Education through mock elections, training workshops, booklets and digital media.  </li>
                        <li>Mobilize and involve youth to actively participate in raising awareness and conduct programs at the local level. </li>
                        <li>Promote free and fair election through a network of nonprofits, CSO and private sectors.</li>
                    </ul>
        `
    },
]

export default ProgramData
