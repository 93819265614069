import React from 'react'
import Banner from '../../components/Banner/Banner'
import ProgramList from './ProgramList'

import ScrollTop from '../../components/ScrollTop'
import './programs.scss'

const Programs = () => {
  return (
    <>
      <ScrollTop />
        <Banner
            image='url(./images/about.jpg)'
            text="<h2>iCAP <b>Programs </b> </h2>"

        />

      <ProgramList />
    </>
  )
}

export default Programs