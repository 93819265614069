const PillarData = [
    {
        background: "#3466ab",
        title: "Electoral Support",
        subtitle: "Helps build the capacity of our electoral institutions",
        text: `
            <ul>
                <li>Impart political literacy through citizen-centric engagements  </li>
                <li>Provide awareness, education and skills to all citizens on electoral system in Nepal </li>
                <li>Enhance digital advocacy and spread awareness regarding voter education/registration  </li>
                <li>Hold debates among candidates and dialogues with voters during local, provincial, and federal elections  </li>
                <li>Conduct all types of election related research and impart the findings to the concerned stakeholders  </li>
            </ul>
        `,
    },
    {
        background: "#4cb759",
        title: "Parliamentarians Support",
        subtitle: "Capacity-building engagements, trips and workshops",
        text: `
            <ul>
                <li>Digital advocacy and awareness regarding the use of technology for parliamentarians.  </li>
                <li>Capacity-building engagements,  trips and workshops for members of federal parliament and provincial assemblies </li>
                <li>Work with the thematic committees of parliaments to enhance their policy developing insights  </li>
            </ul>
        `,
    },
    {
        background: "#ff6b6b",
        title: "Political Parties Support",
        subtitle: "Works to design a Training of Trainers program",
        text: `
            <ul>
                <li>Promote activities and programs to strengthen the internal structure of the political parties; especially for the local committees </li>
                <li>Capacity building and leadership development workshops and trainings to the party members   </li>
                <li>Demand based support and quality enhancement services including knowledge, skills and technology to the political parties and their leaders from local to federal level  </li>
            </ul>
        `,
    },
    {
        background: "#56bbf1",
        title: "Citizen Support Program",
        subtitle: "Bring potential youths together for change",
        text: `
            <h4>Women’s meaningful representation  </h4>
            <ul>
                <li>Strengthen women’s political participation and decision-making power </li>
                <li>Educate women from marginalized communities about the importance of politics and their rights as a female.  </li>
                <li>Promote women in leadership and decision-making roles at all three levels of government. </li>
            </ul>

            <h4>Youth's active and increased engagement   </h4>
            <ul>
                <li>Work on the youth agenda and policy development to identify and alleviate structural disparities.  </li>
                <li>Create a safe platform to encourage discourse amongst youth and senior leaders on various issues.   </li>
                <li>Increase youth participation through leadership training and engagement to enhance intergenerational relations through collaboration </li>
                <li>Conduct various learning camps targeting to the youths in school, college and universities   </li>
                <li>Bring potential youths together for change through making a difference in the societies  </li>
            </ul>

            <h4>Marginalized and excluded groups' inclusion   </h4>
            <ul>
                <li>Provide a dignified platform to the people with disability, LGBTQIA+, people from ethnic, linguistic, geographical and cultural minorities, disadvantaged communities and so on to raise their voice as well as take an affirmative action for all their concerns regarding civic rights </li>
             
            </ul>
        `
    },
    
];



export default PillarData