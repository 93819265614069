import React from 'react'
import { useLocation } from 'react-router-dom'
import Banner from '../../components/Banner/Banner'
import ScrollTop from '../../components/ScrollTop'

const ProgramDetail = () => {
    const location = useLocation()
    const  {detail}  = location.state
  return (
    <>
        <ScrollTop />

        <Banner
            image={`url(${detail.image})`}
            text={`<h2>${detail.title} </h2>`}

        />
        <section className="program_detail">
            <div className="container">
                <p>
                    {detail.text}
                </p>

                <article dangerouslySetInnerHTML={{ __html: detail.description }} ></article>
            </div>
        </section>
    </>
  )
}

export default ProgramDetail