import React from 'react'
import { Routes , Route  } from 'react-router-dom';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import Home from './pages/Home/Home';
import About from './pages/About/About';
import FourPillars from './pages/Pillars/FourPillars';
import Programs from './pages/Programs/Programs';
import Events from './pages/Events/Events';
import ProgramDetail from './pages/Programs/ProgramDetail';
import EventDetail from './pages/Events/EventDetail';
import Team from './pages/About/Team';



const Layout = () => {

  return (
    <>
        
        <Header />
          <Routes>
            <Route path='/' element={<Home  />} />
            <Route path='/about' element={<About  />} />
            <Route path='/team' element={<Team  />} />
            <Route path='/four_pillars' element={<FourPillars  />} />
            <Route path='/programs' element={<Programs  />} />
            <Route path='/program-detail' element={<ProgramDetail  />} />
            <Route path='/events' element={<Events  />} />
            <Route path='/event-detail' element={<EventDetail  />} />
            
                         

          </Routes>
        <Footer />


       
    </>
  )
}

export default Layout