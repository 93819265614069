import React,{useEffect, useState} from 'react'
import {  NavLink } from 'react-router-dom';
import { LogoMain } from '../Logo'



import './header.scss'

const Header = () => {
    const [fixHead , setFixHead] = useState(false)
    
    useEffect(() => {
        var menuBtn = document.querySelector('.mb_btn')
        var navBox = document.querySelector('header nav')

        var navBtns = document.querySelectorAll('header nav li a')

        navBtns.forEach((btn) => {
            btn.addEventListener('click', ()=> {
                menuBtn.classList.remove('close');
                navBox.classList.remove('open'); 
            })
        })

        menuBtn.addEventListener('click', ()=> {
            console.log("clicked")
            menuBtn.classList.toggle('close');
            navBox.classList.toggle('open');
        })

        window.addEventListener('scroll', ()=> {
        
        let scrollValue = window.scrollY
        if(scrollValue > 150) {
            setFixHead(true)
        } else setFixHead(false)


        
        })

        


        
    },[])

 

    return (
        <>
            <header className={fixHead ? 'fix' : '' } >
                <div className="container_large">
                    <div className="wrap">
                        <div className="logo">
                            <NavLink to='/'><LogoMain /></NavLink>
                            
                        </div>

                        <div className="mb_btn">
                            <span></span>
                            <span></span>
                        </div>

                        <nav className=''>
                            <ul>
                                <li><NavLink to='/about'>About</NavLink></li>
                                <li><NavLink to='/four_pillars'>Our Four Pillars</NavLink></li>
                                <li><NavLink to='/programs'>Programs</NavLink> </li>
                                <li><NavLink to='/events'>Events</NavLink></li>
                                <li> <NavLink to='/news'>News & Publication</NavLink></li>
                                <li><NavLink to='/downloads'>Downloads</NavLink></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header