import React from 'react'
import Banner from '../../components/Banner/Banner'
import PillarData from '../Pillars/PillarsData'


import './pillar.scss'

import { Tabs } from 'antd';
import ScrollTop from '../../components/ScrollTop'

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const FourPillars = () => {
  return (
    <>
        <ScrollTop />

        <Banner
            image='url(./images/about.jpg)'
            text="<h2>Our <b>Four Pillars </b> </h2>"

        />

        <section className="pillars page">
            <div className="container_large">
            <Tabs defaultActiveKey="0" onChange={callback}>
                {
                    PillarData.map((val,index) => {
                        return(
                            <TabPane tab={`${val.title}`} key={`${index}`}>
                                <div className="pi_section">
                                    <article dangerouslySetInnerHTML={{ __html: val.text }} ></article>
                                </div>
                            </TabPane>
                        )
                    })
                }

            </Tabs>
            </div>
        </section>
    </>
  )
}

export default FourPillars