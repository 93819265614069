import React from 'react'
import {  NavLink } from 'react-router-dom';
import Banner from '../../components/Banner/Banner'

import { Row, Col } from "antd";

import { ArrowRightOutlined } from "@ant-design/icons";
import ScrollTop from '../../components/ScrollTop'
import EventData from './EventData';

const Events = () => {
  return (
    <>
        <ScrollTop />
        <Banner
            image='url(./images/about.jpg)'
            text="<h2> <b>Events </b> </h2>"

        />

        <section className="events">
            <div className="container_large">
                <Row gutter={[50, 50]}>
                    {
                        EventData.map((val,index)=> {
                            return(
                                <Col span={12} key={index}> 
                                    <div className="ev_card" style={{background: `url(${val.image})` }}  >
                                        <div className="wrap">
                                            <h3>{val.title}</h3>
                                            <article dangerouslySetInnerHTML={{ __html: val.text }} ></article>

                                            <NavLink  
                                                to="/event-detail"
                                                state={{ detail: val }}
                                            >
                                                Learn More <ArrowRightOutlined />
                                            </NavLink>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }

                    <Col span={24}>

                    </Col>

                </Row>
            </div>
        </section>
    </>
    
  )
}

export default Events