import React from 'react'

const LogoMain = () => {
  return (
    <>
        <svg width="190" height="84" viewBox="0 0 190 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M56.6 6.99999C55.9 6.29999 55.5 5.49999 55.5 4.49999C55.5 3.49999 55.9 2.69999 56.6 1.99999C57.3 1.29999 58.1 0.899994 59.1 0.899994C60.1 0.899994 60.9 1.29999 61.6 1.99999C62.3 2.69999 62.7 3.49999 62.7 4.49999C62.7 5.49999 62.3 6.29999 61.6 6.99999C60.9 7.69999 60.1 8.09999 59.1 8.09999C58.2 7.99999 57.3 7.69999 56.6 6.99999ZM56.6 19.1H61.8V61.5H56.6V19.1Z" fill="white"/>
            <path d="M77.4 59.1C74.1 57.2 71.5 54.5 69.5 51.2C67.6 47.9 66.6 44.3 66.6 40.4C66.6 36.5 67.6 32.9 69.5 29.6C71.4 26.3 74.1 23.7 77.4 21.7C80.7 19.8 84.3 18.8 88.2 18.8C92.3 18.8 96 19.8 99.4 21.9C102.8 24 105.5 26.9 107.4 30.5L102.4 32.3C101.1 29.8 99.2 27.7 96.6 26.2C94 24.7 91.2 23.9 88.2 23.9C85.2 23.9 82.5 24.6 79.9 26.1C77.4 27.6 75.4 29.6 73.9 32.1C72.4 34.6 71.7 37.4 71.7 40.4C71.7 43.4 72.4 46.1 73.9 48.7C75.4 51.2 77.4 53.2 79.9 54.7C82.4 56.2 85.2 56.9 88.2 56.9C91.2 56.9 94.1 56.1 96.6 54.6C99.2 53 101.2 50.9 102.6 48.2L107.5 50.1C105.7 53.8 103 56.8 99.5 59C96 61.2 92.2 62.3 88.1 62.3C84.3 62.1 80.7 61.1 77.4 59.1Z" fill="white"/>
            <path d="M137.8 22.6C140.8 25.3 142.3 29.3 142.3 34.5V61.5H137.1V55.2C135.4 57.2 133.4 58.7 131.1 59.9C128.8 61.1 126.3 61.6 123.6 61.6C119.2 61.6 115.6 60.5 112.8 58.2C110 55.9 108.6 52.9 108.6 49.1V49C108.6 45 110.2 41.9 113.4 39.8C116.6 37.7 120.8 36.6 125.9 36.6C127.9 36.6 129.9 36.8 131.8 37.2C133.8 37.6 135.5 38.2 137 38.9V34.5C137 31.2 136 28.5 133.9 26.6C131.8 24.7 129.3 23.7 126.1 23.7C124.1 23.7 122.2 24.2 120.5 25.2C118.8 26.2 117.5 27.6 116.6 29.3L111.4 27.6C112.7 24.8 114.7 22.6 117.3 21C120 19.4 122.9 18.5 126 18.5C131 18.4 134.8 19.8 137.8 22.6ZM130.3 55.4C132.4 54.4 134 53.1 135.2 51.5C136.4 49.9 137 48.1 137 46.1V42.7C133.5 41.5 129.8 40.8 126 40.8C122.4 40.8 119.5 41.5 117.2 43C114.9 44.5 113.8 46.5 113.8 49V49.2C113.8 51.6 114.7 53.4 116.6 54.8C118.4 56.2 120.8 56.8 123.7 56.8C126 56.8 128.2 56.3 130.3 55.4Z" fill="white"/>
            <path d="M178.7 21C182 22.9 184.6 25.5 186.6 28.8C188.5 32.1 189.5 35.7 189.5 39.7C189.5 43.6 188.5 47.2 186.6 50.5C184.7 53.8 182.1 56.5 178.8 58.4C175.5 60.3 171.9 61.3 167.9 61.3C162.3 61.3 157.5 58.9 153.3 54.1V81.4H148V18.8H153.3V25.3C155.3 23.1 157.5 21.3 160 20C162.5 18.7 165.1 18.1 167.8 18.1C171.8 18.1 175.4 19.1 178.7 21ZM176.1 53.9C178.6 52.4 180.6 50.4 182.1 47.9C183.6 45.4 184.3 42.6 184.3 39.6C184.3 36.6 183.6 33.9 182.1 31.3C180.6 28.8 178.6 26.8 176.1 25.3C173.6 23.8 170.8 23.1 167.8 23.1C164.7 23.1 161.8 23.9 159.2 25.6C156.6 27.2 154.6 29.5 153.2 32.3V47.4C154.7 50.1 156.7 52.2 159.3 53.7C161.9 55.3 164.7 56 167.8 56C170.9 56.1 173.6 55.4 176.1 53.9Z" fill="white"/>
            <path d="M26.7 61.4H22.4V54.8C22.4 47.2 28.6 41 36.2 41H42.8V45.3H36.2C31 45.3 26.7 49.5 26.7 54.8V61.4Z" fill="white"/>
            <path d="M42.8 39.6H36.2C28.6 39.6 22.4 33.4 22.4 25.8V19.2H26.7V25.8C26.7 31 30.9 35.3 36.2 35.3H42.8V39.6Z" fill="white"/>
            <path d="M20.9 61.4H16.6V54.8C16.6 49.6 12.4 45.3 7.1 45.3H0.5V41H7.1C14.7 41 20.9 47.2 20.9 54.8V61.4Z" fill="white"/>
            <path d="M7.1 39.6H0.5V35.3H7.1C12.3 35.3 16.6 31.1 16.6 25.8V19.2H20.9V25.8C20.9 33.4 14.7 39.6 7.1 39.6Z" fill="white"/>
            <path d="M36.7 30.4C39.7928 30.4 42.3 27.8928 42.3 24.8C42.3 21.7072 39.7928 19.2 36.7 19.2C33.6072 19.2 31.1 21.7072 31.1 24.8C31.1 27.8928 33.6072 30.4 36.7 30.4Z" fill="white"/>
            <path d="M36.7 61.4C39.7928 61.4 42.3 58.8928 42.3 55.8C42.3 52.7072 39.7928 50.2 36.7 50.2C33.6072 50.2 31.1 52.7072 31.1 55.8C31.1 58.8928 33.6072 61.4 36.7 61.4Z" fill="white"/>
            <path d="M6.1 61.4C9.19279 61.4 11.7 58.8928 11.7 55.8C11.7 52.7072 9.19279 50.2 6.1 50.2C3.00721 50.2 0.5 52.7072 0.5 55.8C0.5 58.8928 3.00721 61.4 6.1 61.4Z" fill="white"/>
            <path d="M6.1 30.4C9.19279 30.4 11.7 27.8928 11.7 24.8C11.7 21.7072 9.19279 19.2 6.1 19.2C3.00721 19.2 0.5 21.7072 0.5 24.8C0.5 27.8928 3.00721 30.4 6.1 30.4Z" fill="white"/>
            <path d="M57.2 66.4H57.9V68.8H60.8V66.4H61.5V72H60.8V69.4H57.9V72H57.2V66.4Z" fill="white"/>
            <path d="M63.4 67.3H62.7V66.5H63.4V67.3ZM62.8 68H63.5V72H62.8V68Z" fill="white"/>
            <path d="M64.5 68H65.1V68.6C65.4 68.1 65.9 67.9 66.4 67.9C66.9 67.9 67.3 68.1 67.5 68.6C67.8 68.2 68.3 67.9 68.8 67.9C69.6 67.9 70.1 68.2 70.1 69.1V72.1H69.4V69.4C69.4 68.9 69.3 68.5 68.6 68.5C68 68.5 67.5 68.9 67.5 69.5V72H67V69.3C67 68.8 66.8 68.4 66.2 68.4C65.4 68.4 65.1 69.1 65.1 69.4V72H64.4L64.5 68Z" fill="white"/>
            <path d="M74.8 72C74.7 72.1 74.5 72.1 74.3 72.1C74 72.1 73.7 71.9 73.7 71.5C73.3 71.9 72.8 72.1 72.3 72.1C71.6 72.1 71 71.8 71 71C71 70.1 71.7 69.9 72.4 69.8C73.1 69.7 73.8 69.7 73.8 69.2C73.8 68.6 73.3 68.5 72.9 68.5C72.3 68.5 71.9 68.7 71.9 69.3H71.2C71.2 68.3 72 67.9 72.9 67.9C73.6 67.9 74.4 68.1 74.4 69V71.1C74.4 71.4 74.4 71.6 74.6 71.6C74.7 71.6 74.7 71.6 74.8 71.6C74.8 71.6 74.8 72 74.8 72ZM73.7 70C73.4 70.2 72.9 70.2 72.5 70.3C72.1 70.4 71.7 70.5 71.7 71C71.7 71.4 72.1 71.6 72.5 71.6C73.4 71.6 73.7 71.1 73.7 70.7V70Z" fill="white"/>
            <path d="M75.4 66.4H76.1V72H75.4V66.4Z" fill="white"/>
            <path d="M80.7 72C80.6 72.1 80.4 72.1 80.2 72.1C79.9 72.1 79.6 71.9 79.6 71.5C79.2 71.9 78.7 72.1 78.2 72.1C77.5 72.1 76.9 71.8 76.9 71C76.9 70.1 77.6 69.9 78.3 69.8C79 69.7 79.7 69.7 79.7 69.2C79.7 68.6 79.2 68.5 78.8 68.5C78.2 68.5 77.8 68.7 77.8 69.3H77.1C77.1 68.3 77.9 67.9 78.8 67.9C79.5 67.9 80.3 68.1 80.3 69V71.1C80.3 71.4 80.3 71.6 80.5 71.6C80.6 71.6 80.6 71.6 80.7 71.6C80.7 71.6 80.7 72 80.7 72ZM79.6 70C79.3 70.2 78.8 70.2 78.4 70.3C78 70.4 77.6 70.5 77.6 71C77.6 71.4 78 71.6 78.4 71.6C79.3 71.6 79.6 71.1 79.6 70.7V70Z" fill="white"/>
            <path d="M82.9 72.6C82.6 73.4 82.3 73.7 81.7 73.7C81.5 73.7 81.4 73.7 81.2 73.6V73C81.3 73 81.5 73.1 81.6 73.1C81.9 73.1 82 73 82.1 72.7L82.4 72L80.8 68H81.6L82.8 71.3L84 68H84.7L82.9 72.6Z" fill="white"/>
            <path d="M88.9 72C88.8 72.1 88.6 72.1 88.4 72.1C88.1 72.1 87.8 71.9 87.8 71.5C87.4 71.9 86.9 72.1 86.4 72.1C85.7 72.1 85.1 71.8 85.1 71C85.1 70.1 85.8 69.9 86.5 69.8C87.2 69.7 87.9 69.7 87.9 69.2C87.9 68.6 87.4 68.5 87 68.5C86.4 68.5 86 68.7 86 69.3H85.3C85.3 68.3 86.1 67.9 87 67.9C87.7 67.9 88.5 68.1 88.5 69V71.1C88.5 71.4 88.5 71.6 88.7 71.6C88.8 71.6 88.8 71.6 88.9 71.6C88.9 71.6 88.9 72 88.9 72ZM87.8 70C87.5 70.2 87 70.2 86.6 70.3C86.2 70.4 85.8 70.5 85.8 71C85.8 71.4 86.2 71.6 86.6 71.6C87.5 71.6 87.8 71.1 87.8 70.7V70Z" fill="white"/>
            <path d="M89.5 68H90.1V68.6C90.4 68.1 90.8 67.9 91.4 67.9C92.5 67.9 92.8 68.5 92.8 69.4V72H92.1V69.3C92.1 68.8 91.8 68.5 91.3 68.5C90.5 68.5 90.1 69 90.1 69.8V72H89.4L89.5 68Z" fill="white"/>
            <path d="M96.2 66.4H96.9V72H96.2V66.4Z" fill="white"/>
            <path d="M98 68H98.6V68.6C98.9 68.1 99.3 67.9 99.9 67.9C101 67.9 101.3 68.5 101.3 69.4V72H100.6V69.3C100.6 68.8 100.3 68.5 99.8 68.5C99 68.5 98.6 69 98.6 69.8V72H98V68Z" fill="white"/>
            <path d="M102.8 70.8C102.8 71.4 103.3 71.6 103.9 71.6C104.3 71.6 104.9 71.5 104.9 71C104.9 70.5 104.2 70.4 103.6 70.2C102.9 70.1 102.3 69.8 102.3 69.1C102.3 68.3 103.1 67.9 103.8 67.9C104.7 67.9 105.4 68.2 105.5 69.2H104.8C104.8 68.7 104.3 68.5 103.9 68.5C103.5 68.5 103 68.6 103 69C103 69.5 103.7 69.6 104.3 69.7C105 69.8 105.6 70.1 105.6 70.9C105.6 71.9 104.7 72.2 103.9 72.2C103 72.2 102.2 71.8 102.2 70.8C102.2 70.8 102.8 70.8 102.8 70.8Z" fill="white"/>
            <path d="M107.3 68H108.1V68.6H107.3V71.1C107.3 71.4 107.4 71.5 107.8 71.5H108.1V72H107.6C106.9 72 106.6 71.9 106.6 71.1V68.5H105.9V68H106.6V66.8H107.3V68Z" fill="white"/>
            <path d="M109.5 67.3H108.8V66.5H109.5V67.3ZM108.8 68H109.5V72H108.8V68Z" fill="white"/>
            <path d="M111.5 68H112.3V68.6H111.5V71.1C111.5 71.4 111.6 71.5 112 71.5H112.3V72H111.8C111.1 72 110.8 71.9 110.8 71.1V68.5H110.1V68H110.8V66.8H111.5V68Z" fill="white"/>
            <path d="M116.4 72H115.8V71.4C115.5 71.9 115.1 72.1 114.5 72.1C113.4 72.1 113.1 71.5 113.1 70.6V68H113.8V70.7C113.8 71.2 114.1 71.5 114.6 71.5C115.4 71.5 115.8 71 115.8 70.2V68H116.5L116.4 72Z" fill="white"/>
            <path d="M118.3 68H119.1V68.6H118.3V71.1C118.3 71.4 118.4 71.5 118.8 71.5H119.1V72H118.6C117.9 72 117.6 71.9 117.6 71.1V68.5H116.9V68H117.6V66.8H118.3V68Z" fill="white"/>
            <path d="M123.3 70.8C123.1 71.7 122.5 72.2 121.6 72.2C120.3 72.2 119.7 71.3 119.7 70.1C119.7 68.9 120.5 68 121.6 68C123 68 123.5 69.3 123.4 70.3H120.4C120.4 71 120.8 71.6 121.7 71.6C122.2 71.6 122.6 71.3 122.8 70.8C122.8 70.8 123.3 70.8 123.3 70.8ZM122.7 69.6C122.7 69 122.2 68.5 121.5 68.5C120.8 68.5 120.4 69 120.3 69.6H122.7Z" fill="white"/>
            <path d="M128 67.9C129.3 67.9 130 68.8 130 70C130 71.2 129.3 72.1 128 72.1C126.7 72.1 126 71.2 126 70C126 68.8 126.7 67.9 128 67.9ZM128 71.6C128.7 71.6 129.3 71.1 129.3 70.1C129.3 69.1 128.7 68.6 128 68.6C127.3 68.6 126.7 69.1 126.7 70.1C126.7 71 127.3 71.6 128 71.6Z" fill="white"/>
            <path d="M131 68.6H130.3V68H131V67.4C131 66.7 131.4 66.4 132.1 66.4C132.2 66.4 132.4 66.4 132.5 66.5V67C132.4 67 132.2 66.9 132.1 66.9C131.8 66.9 131.6 67 131.6 67.4V68H132.4V68.6H131.6V72H131V68.6Z" fill="white"/>
            <path d="M61.1 77.5C60.9 76.7 60.3 76.3 59.5 76.3C58.2 76.3 57.6 77.4 57.6 78.6C57.6 79.9 58.2 80.9 59.5 80.9C60.5 80.9 61 80.2 61.1 79.3H61.8C61.7 80.7 60.8 81.5 59.4 81.5C57.7 81.5 56.8 80.2 56.8 78.6C56.8 77 57.7 75.7 59.5 75.7C60.7 75.7 61.6 76.3 61.8 77.5C61.8 77.5 61.1 77.5 61.1 77.5Z" fill="white"/>
            <path d="M63.4 76.7H62.7V75.9H63.4V76.7ZM62.8 77.4H63.5V81.5H62.8V77.4Z" fill="white"/>
            <path d="M66.3 81.5H65.6L64.1 77.4H64.8L65.9 80.8L67 77.4H67.7L66.3 81.5Z" fill="white"/>
            <path d="M69.1 76.7H68.4V75.9H69.1V76.7ZM68.4 77.4H69.1V81.5H68.4V77.4Z" fill="white"/>
            <path d="M72.9 78.7C72.8 78.2 72.4 77.9 71.9 77.9C70.9 77.9 70.6 78.7 70.6 79.5C70.6 80.2 70.9 81 71.8 81C72.4 81 72.8 80.6 72.9 80H73.6C73.5 81 72.8 81.6 71.8 81.6C70.6 81.6 69.9 80.7 69.9 79.5C69.9 78.3 70.5 77.3 71.8 77.3C72.7 77.3 73.5 77.7 73.5 78.7H72.9Z" fill="white"/>
            <path d="M78.2 75.9H79L81.2 81.5H80.4L79.8 79.8H77.4L76.8 81.5H76L78.2 75.9ZM77.6 79.1H79.5L78.6 76.5L77.6 79.1Z" fill="white"/>
            <path d="M84.4 78.7C84.3 78.2 83.9 77.9 83.4 77.9C82.4 77.9 82.1 78.7 82.1 79.5C82.1 80.2 82.4 81 83.3 81C83.9 81 84.3 80.6 84.4 80H85.1C85 81 84.3 81.6 83.3 81.6C82.1 81.6 81.4 80.7 81.4 79.5C81.4 78.3 82 77.3 83.3 77.3C84.2 77.3 85 77.7 85 78.7H84.4Z" fill="white"/>
            <path d="M86.8 77.4H87.6V78H86.8V80.5C86.8 80.8 86.9 80.9 87.3 80.9H87.6V81.5H87.1C86.4 81.5 86.1 81.4 86.1 80.6V78H85.4V77.4H86.1V76.2H86.8V77.4Z" fill="white"/>
            <path d="M89 76.7H88.3V75.9H89V76.7ZM88.3 77.4H89V81.5H88.3V77.4Z" fill="white"/>
            <path d="M91.8 77.3C93.1 77.3 93.8 78.2 93.8 79.4C93.8 80.6 93.1 81.5 91.8 81.5C90.5 81.5 89.8 80.6 89.8 79.4C89.8 78.2 90.5 77.3 91.8 77.3ZM91.8 81C92.5 81 93.1 80.5 93.1 79.5C93.1 78.5 92.5 78 91.8 78C91.1 78 90.5 78.5 90.5 79.5C90.5 80.4 91.1 81 91.8 81Z" fill="white"/>
            <path d="M94.5 77.4H95.1V78C95.4 77.5 95.8 77.3 96.4 77.3C97.5 77.3 97.8 77.9 97.8 78.8V81.5H97.1V78.8C97.1 78.3 96.8 78 96.3 78C95.5 78 95.1 78.5 95.1 79.3V81.6H94.4L94.5 77.4Z" fill="white"/>
            <path d="M104.7 81.4C104.6 81.5 104.4 81.5 104.2 81.5C103.9 81.5 103.6 81.3 103.6 80.9C103.2 81.3 102.7 81.5 102.2 81.5C101.5 81.5 100.9 81.2 100.9 80.4C100.9 79.5 101.6 79.3 102.3 79.2C103 79.1 103.7 79.1 103.7 78.6C103.7 78 103.2 77.9 102.8 77.9C102.2 77.9 101.8 78.1 101.8 78.7H101C101 77.7 101.8 77.3 102.7 77.3C103.4 77.3 104.2 77.5 104.2 78.4V80.5C104.2 80.8 104.2 81 104.4 81C104.5 81 104.5 81 104.6 81V81.4H104.7ZM103.6 79.4C103.3 79.6 102.8 79.6 102.4 79.7C102 79.8 101.6 79.9 101.6 80.4C101.6 80.8 102 81 102.4 81C103.3 81 103.6 80.5 103.6 80.1V79.4Z" fill="white"/>
            <path d="M105.3 77.4H105.9V78C106.2 77.5 106.6 77.3 107.2 77.3C108.3 77.3 108.6 77.9 108.6 78.8V81.5H108V78.8C108 78.3 107.7 78 107.2 78C106.4 78 106 78.5 106 79.3V81.6H105.3V77.4Z" fill="white"/>
            <path d="M113.3 81.5H112.6V81C112.4 81.4 111.9 81.6 111.3 81.6C110.1 81.6 109.4 80.6 109.4 79.5C109.4 78.4 110 77.4 111.3 77.4C111.7 77.4 112.3 77.6 112.6 78V75.9H113.3V81.5ZM111.4 81C112.3 81 112.6 80.2 112.6 79.5C112.6 78.7 112.2 78 111.3 78C110.4 78 110.1 78.8 110.1 79.6C110.2 80.2 110.5 81 111.4 81Z" fill="white"/>
            <path d="M116.6 75.9H119.1C120.2 75.9 120.8 76.5 120.8 77.6C120.8 78.7 120.2 79.3 119.1 79.3H117.4V81.6H116.7V75.9H116.6ZM117.4 78.5H118.9C119.7 78.5 120.1 78.1 120.1 77.5C120.1 76.8 119.7 76.5 118.9 76.5H117.4V78.5Z" fill="white"/>
            <path d="M123.3 77.3C124.6 77.3 125.3 78.2 125.3 79.4C125.3 80.6 124.6 81.5 123.3 81.5C122 81.5 121.3 80.6 121.3 79.4C121.4 78.2 122 77.3 123.3 77.3ZM123.3 81C124 81 124.6 80.5 124.6 79.5C124.6 78.5 124 78 123.3 78C122.6 78 122 78.5 122 79.5C122.1 80.4 122.6 81 123.3 81Z" fill="white"/>
            <path d="M126.1 75.9H126.8V81.5H126.1V75.9Z" fill="white"/>
            <path d="M128.5 76.7H127.8V75.9H128.5V76.7ZM127.9 77.4H128.6V81.5H127.9V77.4Z" fill="white"/>
            <path d="M132.3 78.7C132.2 78.2 131.8 77.9 131.3 77.9C130.3 77.9 130 78.7 130 79.5C130 80.2 130.3 81 131.2 81C131.8 81 132.2 80.6 132.3 80H133C132.9 81 132.2 81.6 131.2 81.6C130 81.6 129.3 80.7 129.3 79.5C129.3 78.3 129.9 77.3 131.2 77.3C132.1 77.3 132.9 77.7 132.9 78.7H132.3Z" fill="white"/>
            <path d="M135.4 82C135.1 82.8 134.8 83.1 134.2 83.1C134 83.1 133.9 83.1 133.7 83V82.4C133.8 82.4 134 82.5 134.1 82.5C134.4 82.5 134.5 82.4 134.6 82.1L134.9 81.4L133.3 77.4H134.1L135.3 80.7L136.4 77.4H137.1L135.4 82Z" fill="white"/>
        </svg>
    </>
  )
}

const LogoIcon = () => {
    return(
        <>
            <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.6 66.5H34.9V56.1C34.9 44.2 44.6 34.5 56.5 34.5H66.9V41.2H56.5C48.3 41.2 41.6 47.8 41.6 56.1V66.5Z" fill="#0094D9"/>
                <path d="M56.5 32.3C44.6 32.3 34.9 22.6 34.9 10.7V0.300003H41.6V10.7C41.6 18.9 48.2 25.6 56.5 25.6H66.9V32.3H56.5Z" fill="#0094D9"/>
                <path d="M32.5 66.5H25.8V56.1C25.8 47.9 19.2 41.2 10.9 41.2H0.5V34.5H10.9C22.8 34.5 32.5 44.2 32.5 56.1V66.5Z" fill="#0094D9"/>
                <path d="M10.8 32.3H0.5V25.6H10.9C19.1 25.6 25.8 19 25.8 10.7V0.300003H32.5V10.7C32.5 22.6 22.7 32.3 10.8 32.3Z" fill="#0094D9"/>
                <path d="M57.3 17.9C62.1601 17.9 66.1 13.9601 66.1 9.1C66.1 4.2399 62.1601 0.300003 57.3 0.300003C52.4399 0.300003 48.5 4.2399 48.5 9.1C48.5 13.9601 52.4399 17.9 57.3 17.9Z" fill="#0094D9"/>
                <path d="M57.3 66.5C62.1601 66.5 66.1 62.5601 66.1 57.7C66.1 52.8399 62.1601 48.9 57.3 48.9C52.4399 48.9 48.5 52.8399 48.5 57.7C48.5 62.5601 52.4399 66.5 57.3 66.5Z" fill="#0094D9"/>
                <path d="M9.3 66.5C14.1601 66.5 18.1 62.5601 18.1 57.7C18.1 52.8399 14.1601 48.9 9.3 48.9C4.43989 48.9 0.5 52.8399 0.5 57.7C0.5 62.5601 4.43989 66.5 9.3 66.5Z" fill="#0094D9"/>
                <path d="M9.3 17.9C14.1601 17.9 18.1 13.9601 18.1 9.1C18.1 4.2399 14.1601 0.300003 9.3 0.300003C4.43989 0.300003 0.5 4.2399 0.5 9.1C0.5 13.9601 4.43989 17.9 9.3 17.9Z" fill="#0094D9"/>
            </svg>   
        </>
    )
}

export { LogoMain , LogoIcon }
